/* @import "~antd/dist/antd.css"; */

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.App {
  text-align: center;
}

body {
  font-family: "Poppins";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
